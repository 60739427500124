import { useStaticQuery, graphql } from "gatsby"

const useIndexImages = () => {
  const data = useStaticQuery(
    graphql`
      query indexImages {
        header: allFile(filter: { name: { eq: "home_header_1200x798" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              id
            }
          }
        }
        headerLogo: allFile(filter: { name: { eq: "logo_white_1190x1190" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1190) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              id
            }
          }
        }
        navLogo: allFile(filter: { name: { eq: "logo_favi_489x489" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 489) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              id
            }
          }
        }
        logo: allFile(filter: { name: { eq: "logo_white_600x600" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              id
            }
          }
        }
        design: allFile(filter: { name: { eq: "design_bedroom_746x746" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 746) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              id
            }
          }
        }
        built: allFile(filter: { name: { eq: "build_bedroom_746x746" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 746) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              id
            }
          }
        }
        beforeafter: allFile(
          filter: { relativeDirectory: { eq: "_before-after" } }
          sort: { fields: name, order: DESC }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
        misc: allFile(filter: { relativeDirectory: { eq: "_misc" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )
  // gatsby images
  const header = data.header.edges[0].node.childImageSharp.fluid
  const headerLogo = data.headerLogo.edges[0].node.childImageSharp.fluid
  const navLogo = data.navLogo.edges[0].node.childImageSharp.fluid
  const logo = data.logo.edges[0].node.childImageSharp.fluid
  const design = data.design.edges[0].node.childImageSharp.fluid
  const built = data.built.edges[0].node.childImageSharp.fluid
  const beforeafter = data.beforeafter.edges
  const misc = data.misc.edges
  return {
    header,
    headerLogo,
    navLogo,
    logo,
    design,
    built,
    beforeafter,
    misc,
  }
}

export default useIndexImages
