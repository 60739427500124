import React from "react"
import { motion } from "framer-motion"
import Styled from "styled-components"

const Btn = Styled.button`
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
    &:hover {
        svg {
            path {
                stroke: rgba(255, 255, 255, 0.5);
            }
        }
    }
    &:focus {
        outline:0;
    }
`
const Mpath = Styled(motion.path)`
    fill: transparent;
    stroke-width: 3px;
    stroke: ${({ theme }) => theme.white};
    stroke-linecap: round;
`
const Path = props => <Mpath {...props} />

const Toggle = ({ toggle }) => (
  <Btn onClick={toggle}>
    <motion.svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 25 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 25 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.5 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 25 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </motion.svg>
  </Btn>
)

export default Toggle
