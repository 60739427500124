import React from "react"
import Styled from "styled-components"

const Layout = Styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: repeat(auto-fill, minmax(250px, 1fr));;
    grid-auto-flow: dense;
    grid-gap: 10px;
    padding: ${({ pad }) => (pad ? `${pad}px` : `10px`)};
`
const GridLayout = ({ gap, pad, children }) => (
  <Layout gap={gap} pad={pad}>
    {children}
  </Layout>
)
const Item = Styled.div`
    justify-self: stretch;
    place-self: center;
`
const GridItem = ({ col, row, children }) => (
  <Item col={col} row={row}>
    {children}
  </Item>
)

export { GridLayout, GridItem }
