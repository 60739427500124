import React from "react"
import Styled from "styled-components"

const Svg = Styled.svg`
    cursor: pointer;
    &:hover {
        path {
            fill: ${({ theme }) => theme.primary};
        }
    }
`

const ArrowLeft = ({ width, color, onClick, title, className }) => (
  <Svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="chevron-circle-left"
    role="img"
    className={className}
    width={width ? width : 30}
    onClick={onClick}
    viewBox="0 0 512 512"
  >
    {title && <title>{title}</title>}
    <path
      fill={color ? color : "currentColor"}
      d="M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zM256 472c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm-86.6-224.5l115.1-115c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L209 256l99.5 99.5c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0l-115.1-115c-4.6-4.8-4.6-12.4.1-17.1z"
    />
  </Svg>
)

export default ArrowLeft
