import React from "react"
import Styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import Queries from "@components/StyledElements/Queries"

const Content = Styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`

const BackgroundSection = ({ fluid, alt, children, height }) => (
  <BackgroundImage
    Tag="section"
    fluid={fluid}
    alt={alt}
    style={{ minHeight: "100vh", height: height ? "auto" : height }}
  >
    <Content>{children}</Content>
  </BackgroundImage>
)

const StyledBackgroundSection = Styled(BackgroundSection)`
    width: 100%;
    background-position: bottom center;
    background-repeat: repeat-y;
    background-size: cover;
    ${Queries.mobile.down} {
        height: fit-content;
    }
`

export default StyledBackgroundSection
