import React from "react"
import Styled from "styled-components"

const A = Styled.a`
    color: ${({ theme }) => theme.white};
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 20px;
    &:hover {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.5);
        path {
            fill: rgba(255, 255, 255, 0.5);
        }
    }
`
const ExternalLink = ({
  email = null,
  phone = null,
  key,
  className,
  href,
  children,
  target = "_blank",
}) => {
  if (email) {
    return (
      <A
        key={key}
        className={className}
        href={`mailto:${email}`}
        rel="noopener noreferrer"
        target={target}
      >
        {children}
      </A>
    )
  }
  if (phone) {
    return (
      <A
        key={key}
        className={className}
        href={`tel:+${phone}`}
        rel="noopener noreferrer"
        target={target}
      >
        {children}
      </A>
    )
  }
  return (
    <A
      key={key}
      className={className}
      href={href}
      rel="noopener noreferrer"
      target={target}
    >
      {children}
    </A>
  )
}

export default ExternalLink
