import React from "react"
import Styled from "styled-components"

const Circle = Styled.div`
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid ${({ theme }) => theme.white};
    border-radius: 50%;
    height: ${({ width }) => `${width}px`};
    position: relative;
    width: ${({ width }) => `${width}px`};
    svg {
        path {
            fill: ${({ theme }) => theme.white};
        }
    }
    &:hover {
        border: 1px solid rgba(255, 255, 255, 0.5);
        svg {
            path {
                fill: rgba(255, 255, 255, 0.5);
            }
        }
        
    }
`
const Svg = Styled.svg`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
`
const Facebook = ({ width, color, onClick, title, dark }) => (
  <Circle width={width ? width : 30} dark={dark} onClick={onClick}>
    <Svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="facebook-f"
      role="img"
      onClick={onClick}
      viewBox="0 0 320 512"
    >
      {title && <title>{title}</title>}
      <path
        fill={color ? color : "currentColor"}
        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
      />
    </Svg>
  </Circle>
)

export default Facebook
