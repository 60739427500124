const DEV = false
export const SERVERLESS_URL = DEV
  ? "http://localhost:9000/.netlify/functions/contact"
  : "/.netlify/functions/contact"

export const SECTIONS = [
  { name: `About`, path: `about` },
  { name: `Contact`, path: `contact` },
  { name: `Services`, path: `services` },
  { name: `Dream`, path: `dream` },
  { name: `Gallery`, path: `gallery` },
  { name: `Testimonials`, path: `testimonials` },
]
export const TESTIMONIALS = [
  {
    testimonial: `"My wife and I chose to use Adam at Lee Construction Company to do some work on our home after hearing from friends how amazing his work is. We are very particular and wanted high end work and Adam did not disappoint. His team was very respectful in our home, did great work, and even cleaned up after them selves. I was really impressed when I got home and all of our furniture in our home had been moved back to its original spot. The work he did was beyond what we had imagined. I would highly recommend Adam and Lee Construction Company to anyone looking for quality work. Thank you Adam."`,
    client: `~ The Jennings`,
  },
  {
    testimonial: `"Lee Construction Company has got everything I need. It's all good. Just what I was looking for."`,
    client: `~ Jojo F`,
  },
  {
    testimonial: `"Keep up the excellent work. Lee Construction Company saved my business. Dude, your stuff is the bomb!"`,
    client: `~ Courtney S`,
  },
]
