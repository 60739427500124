import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

const ScrollToLink = ({ to, toggle, children }) => {
  if (toggle) {
    return (
      <a
        onClick={() => {
          toggle()
          scrollTo(`#${to}`)
        }}
      >
        {children}
      </a>
    )
  }
  return <a onClick={() => scrollTo(`#${to}`)}>{children}</a>
}

export default ScrollToLink
