import * as React from "react"
import { motion } from "framer-motion"
import Styled from "styled-components"

import Item from "./Item"
import { SECTIONS } from "../../config"

const UL = Styled(motion.ul)`
    padding: 25px;
    position: absolute;
    top: 40px;
    right: 0px;
    li {
        color: ${({ theme }) => theme.white};
        list-style: none;
        margin-bottom: 20px;
        font-size: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        &:hover {
            text-decoration: underline;
        }
    }
`

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const NavItems = ({ isOpen, toggle }) => (
  <UL variants={variants}>
    {SECTIONS.map(route => (
      <Item
        toggle={toggle}
        isOpen={isOpen}
        key={route.name}
        path={route.path}
        name={route.name}
      />
    ))}
  </UL>
)

export default NavItems
