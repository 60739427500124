import React from "react"
import Styled from "styled-components"

const Svg = Styled.svg`
    cursor: pointer;
    path {
        fill: ${({ theme }) => theme.primary};
    }
    &:hover {
        path {
            fill: ${({ theme }) => theme.secondary};
        }
    }
`

const StarEmpty = ({ width, color, onClick, title, className }) => (
  <Svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="star"
    role="img"
    viewBox="0 0 576 512"
    className={className}
    width={width ? width : 20}
    onClick={onClick}
  >
    {title && <title>{title}</title>}
    <path
      fill={color ? color : "currentColor"}
      d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"
    />
  </Svg>
)

export default StarEmpty
