import React from "react"
import { motion } from "framer-motion"
import Styled from "styled-components"

import ScrollToLink from "@components/ScrollToLink"

const RouteContainer = Styled(motion.li)`
    display: ${({ isOpen }) => (!isOpen ? "none" : "flex")}
`
const Route = Styled(ScrollToLink)`
    color: ${({ theme }) => theme.white};
    border-radius: 5px;
    width: 200px;
    height: 30px;
    flex: 1;
    font-size: 35px;
    text-align: right;
    justify-content: flex-end;
`
const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const Item = ({ path, name, isOpen, toggle }) => {
  return (
    <RouteContainer
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      isOpen={isOpen}
    >
      <Route to={path} toggle={toggle}>
        {name}
      </Route>
    </RouteContainer>
  )
}

export default Item
