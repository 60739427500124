import React from "react"
import Styled from "styled-components"
import { motion } from "framer-motion"

const svgVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
}
const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { duration: 2, ease: "easeInOut" },
  },
}
const polyVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { duration: 3, ease: "easeInOut" },
  },
}
const Svg = Styled(motion.svg)`
    path, polygon {
        fill: black;
    }
`

const Logo = ({ width, title }) => (
  <Svg
    variants={svgVariants}
    initial="hidden"
    animate="visible"
    viewBox="0 0 406.64 419"
    width={width ? width : 100}
  >
    {title && <title>{title}</title>}
    <motion.path
      variants={pathVariants}
      d="M96.68,90.5v419H503.32V90.5Zm361.82,368h-317v-317h317Z"
      transform="translate(-96.68 -90.5)"
    />
    <motion.polygon
      variants={polyVariants}
      points="134.22 88.01 173.3 88.01 173.3 294.15 272.42 294.15 272.42 330.99 134.22 330.99 134.22 88.01"
    />
  </Svg>
)

export default Logo
