import React from "react"
import { motion, useCycle } from "framer-motion"
import Styled from "styled-components"

import { Queries } from "@components/StyledElements"
import MenuToggle from "./Toggle"
import Items from "./Items"

const Menu = Styled(motion.nav)`
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 450px;
    height: 450px;
    display: none;
    ${Queries.mobile.down} {
        display: flex;
    }
`
const Background = Styled(motion.div)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    background: ${({ theme, isOpen }) =>
      isOpen ? theme.black : "transparent"};
`
const SideBarVars = {
  open: {
    // clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    clipPath: `circle(85% at 100% 0)`,
    height: "450px",
    width: "450px",
    transition: {
      ease: "easeOut",
      duration: 0.1,
    },
  },
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    height: "80px",
  },
}

const MyMenu = () => {
  const [isOpen, toggleOpen] = useCycle(false, true)

  return (
    <Menu
      initial={"closed"}
      animate={isOpen ? "open" : "closed"}
      isOpen={isOpen}
    >
      <Background
        className="background"
        isOpen={isOpen}
        variants={SideBarVars}
      />
      <Items isOpen={isOpen} toggle={() => toggleOpen()} />
      <MenuToggle toggle={() => toggleOpen()} />
    </Menu>
  )
}

export default MyMenu
