import React from "react"
import Styled from "styled-components"

const Svg = Styled.svg`
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
    path {
        fill: black;
    }
    &:hover {
        path {
            fill: ${({ theme }) => theme.primary};
        }
    }
`

const Painting = ({ width, color, onClick, title }) => (
  <Svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="paint-roller"
    role="img"
    viewBox="0 0 512 512"
    width={width ? width : 15}
    onClick={onClick}
  >
    {title && <title>{title}</title>}
    <path
      fill={color ? color : "currentColor"}
      d="M416 128V32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v96c0 17.67 14.33 32 32 32h352c17.67 0 32-14.33 32-32zm32-64v128c0 17.67-14.33 32-32 32H256c-35.35 0-64 28.65-64 64v32c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32v-32h160c53.02 0 96-42.98 96-96v-64c0-35.35-28.65-64-64-64z"
    />
  </Svg>
)

export default Painting
