import React from "react"
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Styled from "styled-components"
import "./swiper.css"

SwiperCore.use([Navigation, Pagination, Autoplay])

const TestimonialItem = Styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        line-height: 1.5rem;
    }
`

const TestimonialCarousel = ({ testimonials }) => (
  <Swiper
    slidesPerView={1}
    pagination={{
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 3,
    }}
    autoplay={{ delay: 5000, reverseDirection: true }}
  >
    {testimonials.map((item, i) => {
      const { testimonial, client } = item
      return (
        <SwiperSlide key={`testimonial-${i}-${client}`}>
          <TestimonialItem>
            <p>{testimonial}</p>
            <p>
              <i>{client}</i>
            </p>
          </TestimonialItem>
        </SwiperSlide>
      )
    })}
  </Swiper>
)

export default TestimonialCarousel
