/**
 * mobile portrait: less than 640px
 * mobile landscape: > 640px
 * tablet portrait: > 768px
 * tablet landscape: > 1024px
 * laptop: > 1280px
 */

const BREAKPOINTS = {
  mobile: {
    up: `@media (min-width: 640px)`,
    down: `@media (max-width: 640px)`,
  },
  tablet_portrait: {
    up: `@media (min-width: 768px)`,
    down: `@media (max-width: 768px)`,
  },
  tablet_landscape: {
    up: `@media (min-width: 1024px)`,
    down: `@media (max-width: 1024px)`,
  },
  laptop: {
    up: `@media (min-width: 1280px)`,
    down: `@media (max-width: 1280px)`,
  },
}

export default BREAKPOINTS
