import React from "react"
import Styled from "styled-components"

const Svg = Styled.svg`
    width: ${({ width }) => (width ? width : "1.1rem")};
    height: ${({ height }) => (height ? height : "1.1rem")};
    transition: all 0.5s east-out;
    transform: ${({ open }) => (open ? "rotate(0deg)" : "rotate(45deg)")};
`

const Open = ({ width, height, color, open }) => (
  <Svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="times-octagon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    height={height ? height : 50}
    width={width ? width : 50}
    open={open}
  >
    <path
      fill={color ? color : "currentColor"}
      d="M497.9 150.5L361.5 14.1c-9-9-21.2-14.1-33.9-14.1H184.5c-12.7 0-24.9 5.1-33.9 14.1L14.1 150.5c-9 9-14.1 21.2-14.1 33.9v143.1c0 12.7 5.1 24.9 14.1 33.9l136.5 136.5c9 9 21.2 14.1 33.9 14.1h143.1c12.7 0 24.9-5.1 33.9-14.1L498 361.4c9-9 14.1-21.2 14.1-33.9v-143c-.1-12.8-5.2-25-14.2-34zm-33.9 177L327.5 464h-143L48 327.5v-143L184.5 48h143.1L464 184.5v143zm-106.2 7.7l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17z"
    />
  </Svg>
)

export default Open
