import React from "react"
import Styled from "styled-components"

const Svg = Styled.svg`
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
    path {
        fill: black;
    }
    &:hover {
        path {
            fill: ${({ theme }) => theme.primary};
        }
    }
`

const Build = ({ width, color, onClick, title }) => (
  <Svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="shovel"
    role="img"
    viewBox="0 0 512 512"
    width={width ? width : 15}
    onClick={onClick}
  >
    {title && <title>{title}</title>}
    <path
      fill={color ? color : "currentColor"}
      d="M500.3 96.38L415.62 11.7c-15.59-15.59-40.97-15.59-56.56 0l-32.67 32.67c-18.94 18.94-29.25 44.31-29.25 70.55 0 6.53.64 13.13 1.95 19.69 1.74 8.69 4.75 16.84 8.53 24.53L197.36 269.39l-45.26-45.26c-12.5-12.5-32.76-12.5-45.25 0l-67.88 67.88C-11.02 342-6.29 473.03 16.34 495.66c22.63 22.63 153.66 27.36 203.65-22.63l67.88-67.88c12.5-12.5 12.5-32.76 0-45.25l-45.26-45.26 110.26-110.26c7.7 3.79 15.87 6.8 24.57 8.54 32.81 6.56 66.53-3.64 90.19-27.3l32.67-32.67c15.6-15.6 15.6-40.97 0-56.57zm-77.92 43.99a35.87 35.87 0 0 1-32.42 9.8c-14.23-2.86-25.27-13.91-28.13-28.17-2.33-11.83 1.31-23.91 9.8-32.38l15.7-15.7 50.75 50.75-15.7 15.7z"
    />
  </Svg>
)

export default Build
