import React from "react"
import Styled from "styled-components"

const Svg = Styled.svg`
    cursor: pointer;
`

const Phone = ({ width, color, onClick, title }) => (
  <Svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="phone-rotary"
    role="img"
    viewBox="0 0 512 512"
    width={width ? width : 50}
    onClick={onClick}
  >
    {title && <title>{title}</title>}
    <path
      fill={color ? color : "currentColor"}
      d="M370.43 192.25A64 64 0 0 0 314.86 160H197.14a64 64 0 0 0-55.57 32.25L36.22 376.62A32 32 0 0 0 32 392.5V448a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32v-55.5a32 32 0 0 0-4.22-15.88zM432 432H80v-35.25l103.25-180.69a16 16 0 0 1 13.89-8.06h117.72a16 16 0 0 1 13.89 8.06L432 396.75zM256 256a64 64 0 1 0 64 64 64 64 0 0 0-64-64zm250.18-133C436.76 65 347.38 32 256 32S75.24 65 5.82 123A16.45 16.45 0 0 0 0 135.64V192a16 16 0 0 0 16 16h70.11a16 16 0 0 0 14.31-8.85L128 128c39.9-17.28 83.2-24 128-24 44.77 0 88.07 6.72 128 24l27.58 71.15a16 16 0 0 0 14.31 8.85H496a16 16 0 0 0 16-16v-56.36a16.45 16.45 0 0 0-5.82-12.64z"
    />
  </Svg>
)

export default Phone
